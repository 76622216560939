import type { SystemRouteData, RouteData } from 'routes';

const PREFIX = 'EVENT/' as const;

type NavigationOptions = {
  omitScroll?: boolean;
  replaceHistory?: boolean;
}

type InternalUrlPayload = {
  url: string;
}

type SystemRoutePayload = {
  to: SystemRouteData;
}

type RoutePayload = {
  to: RouteData;
  url: string;
}

type NavigateToAction = {
  type: typeof NAVIGATION_REQUESTED;
  payload: (InternalUrlPayload | SystemRoutePayload | RoutePayload) & {
    omitScroll?: boolean;
    replaceHistory?: boolean;
  };
}

export const LANGUAGE_CHANGED = `${PREFIX}LANGUAGE_CHANGED` as const;
export const languageChanged = () => ({ type: LANGUAGE_CHANGED });

export const SELECTED_FILTERS_CHANGED = `${PREFIX}SELECTED_FILTERS_CHANGED` as const;
export const searchFiltersChanged = () => ({ type: SELECTED_FILTERS_CHANGED });

export const VIEWER_CHANGED = `${PREFIX}VIEWER_CHANGED` as const;
export const viewerChanged = () => ({ type: VIEWER_CHANGED });

export const BASKET_CHANGE_STARTED = `${PREFIX}BASKET_CHANGE_STARTED` as const;
export const basketChangeStarted = () => ({ type: BASKET_CHANGE_STARTED });

export const BASKET_CHANGE_COMPLETED = `${PREFIX}BASKET_CHANGE_COMPLETED` as const;
export function basketChangeCompleted(updatedLinesAmount: number): BasketChangeAction;
export function basketChangeCompleted(updateInfo: BasketChangeInfo): BasketChangeAction;
export function basketChangeCompleted(arg: number | BasketChangeInfo): BasketChangeAction {
  return {
    type: BASKET_CHANGE_COMPLETED,
    payload: typeof arg === 'number'
      ? { linesAmount: arg }
      : arg,
  };
}

type BasketChangeInfo = {
  updaterId: string;
  date: number;
  linesAmount: number;
};

type BasketChangeAction = {
  type: typeof BASKET_CHANGE_COMPLETED;
  payload: {
    updaterId?: string;
    date?: number;
    linesAmount: number;
  };
};

export const LOCATION_CHANGED = `${PREFIX}LOCATION_CHANGED` as const;
export const locationChanged = () => ({ type: LOCATION_CHANGED });
export type LocationChangedAction = ReturnType<typeof locationChanged>;

export const NAVIGATION_REQUESTED = `${PREFIX}NAVIGATION_REQUESTED` as const;
const emptyOptions = {};

export function navigateTo(to: SystemRouteData, url?: string, options?: NavigationOptions): NavigateToAction;
export function navigateTo(to: RouteData | undefined, url: string, options?: NavigationOptions): NavigateToAction;
export function navigateTo(to?: RouteData, url?: string, { omitScroll, replaceHistory }: NavigationOptions = emptyOptions): NavigateToAction {
  if (to && url)
    return {
      type: NAVIGATION_REQUESTED,
      payload: { to, url, omitScroll, replaceHistory },
    };

  if (to)
    return {
      type: NAVIGATION_REQUESTED,
      payload: { to: to as SystemRouteData, omitScroll, replaceHistory },
    };

  return {
    type: NAVIGATION_REQUESTED,
    payload: { url: url as string, omitScroll, replaceHistory },
  };
}
