import { shippingAddressPageQuery, newShippingAddressPageQuery, getLoadShippingAddresssPreviewPageQuery } from './queries';
import { initPageContent } from '../system';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouteName } from 'routes';
import { PageComponentNames } from '../componentNames';

export default ({ routeName, params }, _state$, { api }) => {
  const isEdit = routeName === RouteName.EditShippingAddress;
  const pageName = isEdit ? 'editShippingAddress' : 'newShippingAddress';

  if (params?.previewToken) {
    return api.graphApi(getLoadShippingAddresssPreviewPageQuery(pageName)).pipe(
      map(({ pages, profile: { myShippingInfo } }) => {
        const page = 'editShippingAddress' in pages ? pages.editShippingAddress : pages.newShippingAddress;
        if (!page)
          return null;

        const result = {
          page: initPageContent(page),
        };

        result.page.shippingAddress = { templateFields: myShippingInfo.templateFields };

        result.page.component = PageComponentNames.ShippingInfoDetails;
        return result;
      }),
    );
  }

  const id = params?.id;

  if (isEdit && !id)
    return of(null);

  const request = isEdit
    ? api.graphApi(shippingAddressPageQuery, { id })
    : api.graphApi(newShippingAddressPageQuery);

  return request.pipe(
    map(({ pages, profile: { myShippingInfo } }) => {
      const page = 'editShippingAddress' in pages ? pages.editShippingAddress : pages.newShippingAddress;

      if (!page || !myShippingInfo)
        return null;

      const result = {
        page: initPageContent(page),
      };
      result.page.component = PageComponentNames.ShippingInfoDetails;

      result.page.shippingAddress = isEdit 
        ? myShippingInfo.shippingAddress 
        : { templateFields: myShippingInfo.templateFields };

      if (!result.page.shippingAddress)
        return null;

      return result;
    }),
  );
};