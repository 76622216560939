import { memo } from 'react';
import { UseSanaTexts } from 'components/sanaText';

const preloadTexts = [
  'ShoppingBasket_QuantityPostfix',
  'BasketSummary_AddingProduct',
  'ShoppingBasket_ProductAddedHeader',
  'SummaryPopup_Header',
  'Basket_Separator',
  'ShoppingBasket_Updated',
];

const None = () => null;
export default memo(() => <UseSanaTexts options={preloadTexts}>{None}</UseSanaTexts>);