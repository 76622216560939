export const enum ProductSpecificationFilter {
  ForList = 'FOR_LIST',
  ForDetails = 'FOR_DETAILS',
  ForComparison = 'FOR_COMPARISON',
}

export const enum ManufacturerImageFormat {
  List = 'LIST',
  Details = 'DETAILS',
}
