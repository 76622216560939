import { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/primitives/form';
import { manufacturerSelection, requestManufacturers } from 'behavior/products/search/searchFilters';
import { useSimpleTexts } from 'components/sanaText';
import styles from './SearchFilters.module.scss';
import { connect } from 'react-redux';

const ManufacturerSelector = ({ disabled, selectedManufacturer, manufacturerSelection, manufacturers, requestManufacturers }) => {
  const [selectTitle] = useSimpleTexts(['SelectManufacturer']).texts;

  const handleSelectOfManufacturer = useCallback(value => {
    manufacturerSelection(value);
  }, [manufacturers]);

  useEffect(() => {
    manufacturers?.length === 0 && requestManufacturers();
  }, [manufacturers, requestManufacturers]);

  const options = useMemo(() => {
    if (manufacturers.length === 0)
      return null;

    const options = [];
    options.push({ name: selectTitle, value: null });

    for (const { code, name } of manufacturers) {
      options.push({ name: name || code, value: code });
    }

    return options;
  }, [manufacturers, selectTitle]);

  if (options === null)
    return null;

  return (
    <div className={styles.manufacturerSelector}>
      <Select className={styles.manufacturerSelect} value={selectedManufacturer} items={options} onChange={handleSelectOfManufacturer} disabled={disabled} id="ManufacturerSelector" />
    </div>
  );
};

ManufacturerSelector.propTypes = {
  disabled: PropTypes.bool,
  selectedManufacturer: PropTypes.string,
  manufacturerSelection: PropTypes.func,
  manufacturers: PropTypes.array,
  requestManufacturers: PropTypes.func,
};

export default connect(({ searchFilters: { disabled, selectedManufacturer, manufacturers } }) => ({ disabled, selectedManufacturer, manufacturers }),
  { manufacturerSelection, requestManufacturers })(ManufacturerSelector);