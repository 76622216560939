import loadable from '@loadable/component';

const MyShippingInfo = loadable(() => import('./MyShippingInfo'));
const MyShippingInfoDetails = loadable(() => import('./Details'));

/* eslint-disable react/no-multi-comp */
export function myShippingInfoRenderer(page) {
  return <MyShippingInfo shippingInfo={page.shippingInfo} />;
}

export function myShippingInfoDetailsRenderer(page) {
  return <MyShippingInfoDetails shippingAddress={page.shippingAddress} />;
}