import {
  loadCalculatedFieldsQuery,
  loadProductsGeneralInfoQuery,
} from './queries';
import {
  PRODUCTLIST_CALCULATED_FIELDS_REQUESTED,
  PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED,
  productsUpdated,
  productsGeneralInfoLoaded,
} from 'behavior/pages/productList/actions';
import {
  map,
  takeUntil,
  mergeMap,
  switchMap,
  pluck,
  startWith,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { LOCATION_CHANGED, navigateTo, SELECTED_FILTERS_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { parseQuery, stringifyQuery } from 'utils/url';
import { EMPTY, merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { shallowEqual } from 'react-redux';
import { getViewModeProductAmount, getSortOptionKey, sortOptionsAreEqual } from './helpers';
import { RouteName } from 'routes';

export default (action$, state$, { api, logger, scope }) => {
  const locationChanged$ = action$.pipe(
    ofType(LOCATION_CHANGED),
    withLatestFrom(state$, isLocationChanged),
    filter(Boolean),
  );

  const calculatedFieldsLoad$ = action$.pipe(
    ofType(PRODUCTLIST_CALCULATED_FIELDS_REQUESTED),
    mergeMap(({ payload: { options } }) =>
      api.graphApi(loadCalculatedFieldsQuery, { options }).pipe(
        map(data => productsUpdated(data.catalog.products.products)),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      )),
  );

  const productsGeneralInfoLoad$ = action$.pipe(
    ofType(PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED),
    withLatestFrom(state$),
    switchMap(([action, state]) => api.graphApi(loadProductsGeneralInfoQuery({
      isInsiteEditor: state.insiteEditor.initialized,
    }), {
      id: action.payload.listPageId,
      options: action.payload.options,
      loadLargeImages: scope === 'SERVER',
      loadCategories: state.analytics && state.analytics.isTrackingEnabled,
    }, { retries: 0 })
      .pipe(
        pluck('pages', 'productList', 'products', 'products'),
        mergeMap(products => of(
          productsGeneralInfoLoaded(
            products,
            action.payload.appendProducts,
            action.payload.options.page.size,
          ),
          unsetLoadingIndicator(),
        )),
        retryWithToast(action$, logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const filterProducts$ = action$.pipe(
    ofType(SELECTED_FILTERS_CHANGED),
    switchMap(_action => {
      const {
        routing: {
          routeData,
          location: { pathname, search },
          canonicalUrl,
        },
        settings: {
          productList: {
            listProductAmount,
            gridProductAmount,
          },
        },
        page: {
          defaultViewMode,
          defaultSorting,
        },
      } = state$.value;

      if (!productListRouteNames.has(routeData.routeName))
        return EMPTY;

      const options = {
        listProductAmount,
        gridProductAmount,
        defaultViewMode,
        defaultSorting,
      };
      const canonicalSearch = canonicalUrl ? canonicalUrl.split('?')[1] || '' : search;
      const params = { ...routeData.params };
      const viewMode = params.viewMode || options.defaultViewMode;
      params.count = getViewModeProductAmount(viewMode, options);

      const { count, sort, page, ...query } = parseQuery(canonicalSearch);

      addSortingParam(query, params, options);
      addViewModeParam(query, params, options);

      const url = pathname + stringifyQuery(query);
      const to = {
        ...routeData,
        params,
      };

      return of(navigateTo(to, url, { omitScroll: true }));
    }),
  );

  return merge(calculatedFieldsLoad$, productsGeneralInfoLoad$, filterProducts$);
};

function addSortingParam(query, { sort }, options) {
  if (sort && !sortOptionsAreEqual(sort, options.defaultSorting))
    query.sort = getSortOptionKey(sort.field, sort.ascending);
}

function addViewModeParam(query, { viewMode }, options) {
  if (viewMode && viewMode !== options.defaultViewMode)
    query['view-mode'] = viewMode.toLowerCase();
}

const productListRouteNames = new Set([
  RouteName.ProductList,
  RouteName.Search,
  RouteName.ProductsWithCategory,
]);

function isLocationChanged(_action, state) {
  const { routing: { location, navigatingTo } } = state;
  if (!location)
    return true;

  const navigatingLocation = navigatingTo.location;
  if (location.pathname !== navigatingLocation.pathname)
    return true;

  const query = parseQuery(location.search);
  const navigatingQuery = parseQuery(navigatingLocation.search);
  delete query.count;
  delete navigatingQuery.count;

  return !shallowEqual(query, navigatingQuery);
}

