import { Autosuggest, highlightStringBySpans } from 'components/primitives/autosuggest';
import { escapeRegexCharacters } from 'utils/helpers';
import { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { requestModels, modelSelection, requestModelTitle, modelsClear } from 'behavior/products/search/searchFilters/actions';
import { debounce } from 'lodash';
import { useSimpleTexts } from 'components/sanaText';
import SuggestionItem from './SuggestionItem';
import SuggestionsContainer from './SuggestionsContainer';
import styles from './SearchFilters.module.scss';
import PropTypes from 'prop-types';

const ModelSelector = ({
  suggestions,
  requestModels,
  modelSelection,
  selectedModel,
  requestModelTitle,
  currentLanguage,
  selectedModelTitle,
  modelsClear,
  disabled,
}) => {
  const [modelsSearchWatermark] = useSimpleTexts(['ModelsSearchWatermark']).texts;
  const placeholderRef = useRef(modelsSearchWatermark);
  const [suggestionValue, setSuggestionValue] = useState('');
  useEffect(() => {
    selectedModel && requestModelTitle();
  }, [currentLanguage]);

  placeholderRef.current = selectedModelTitle || modelsSearchWatermark;
  const onChange = useCallback((_event, { newValue }) => {
    setSuggestionValue(newValue);
  }, []);

  const search = useMemo(
    () => debounce(value => requestModels(value, 10), 250),
    [],
  );

  const onSuggestionsFetchRequested = useCallback(({ value, reason }) => {
    if (reason === 'input-changed' && value.trim().length > 0) {
      search(value);
    }
  }, []);

  const onSelected = useCallback((event, { suggestion }) => {
    event.preventDefault();
    setSuggestionValue('');
    placeholderRef.current = suggestion.title;
    modelSelection(suggestion);
  }, []);

  const onSuggestionsClearRequested = () => {
    setSuggestionValue('');
    modelsClear();
  };

  const highlightedSuggestions = useMemo(() => {
    return getSuggestions(suggestionValue, suggestions);
  }, [suggestions]);

  const inputProps = {
    placeholder: placeholderRef.current,
    value: suggestionValue,
    onChange,
    type: 'search',
    disabled,
  };

  return (
    <div className={`${styles.modelSelector} ${selectedModelTitle ? styles.modelSelected : ''}`}>
      <Autosuggest
        suggestions={highlightedSuggestions}
        onFetchRequested={onSuggestionsFetchRequested}
        onClearRequested={onSuggestionsClearRequested}
        onSelected={onSelected}
        getItemValue={suggestion => suggestion.title}
        renderItem={suggestion => <SuggestionItem text={suggestion.highlightedText} />}
        renderItemsContainer={SuggestionsContainer}
        inputProps={inputProps}
        theme={styles}
      />
    </div>
  );
};

const mapStateToProps = ({
  searchFilters,
  localization,
}) => ({
  suggestions: searchFilters.models,
  selectedModel: searchFilters.selectedModel,
  selectedManufacturer: searchFilters.selectedManufacturer,
  currentLanguage: localization.currentLanguage,
  selectedModelTitle: searchFilters.modelTitle,
  disabled: searchFilters.disabled,
});

ModelSelector.propTypes = {
  suggestions: PropTypes.array,
  selectedModel: PropTypes.string,
  currentLanguage: PropTypes.object,
  selectedModelTitle: PropTypes.string,
  disabled: PropTypes.bool,
  requestModels: PropTypes.func,
  modelSelection: PropTypes.func,
  requestModelTitle: PropTypes.func,
  modelsClear: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { requestModels, modelSelection, requestModelTitle, modelsClear },
)(ModelSelector);

function getSuggestions(value, models) {
  const escapedValue = escapeRegexCharacters(value.trim());
  const regex = new RegExp(`(${escapedValue})`, 'gi');

  return models
    .map(model => ({
      ...model,
      highlightedText: highlightStringBySpans(model.title, regex, styles.highlight),
    }));
}