import { createReducer } from 'utils/redux';
import {
    DEFAULT_SHIPPING_ADDRESS_ID_UPDATED,
    SHIPPING_ADDRESS_REMOVED,
} from './actions';

export default createReducer(null, {
    [SHIPPING_ADDRESS_REMOVED]: onShippingAddressRemoved,
    [DEFAULT_SHIPPING_ADDRESS_ID_UPDATED]: onDefaultShippingAddressIdUpdated,
  });
  
function onShippingAddressRemoved(state, { payload }) {
    return {
        ...state,
        shippingInfo: {
            ...state.shippingInfo,
            shippingAddresses: state.shippingInfo.shippingAddresses.filter(a => a.id !== payload.shippingAddressId),
        },
    };
}

function onDefaultShippingAddressIdUpdated(state, { payload }) {
    return {
        ...state,
        shippingInfo: {
            ...state.shippingInfo,
            defaultAddressId: payload,
        },
    };
}
  