import styles from './SearchFilters.module.scss';
import { useSanaTexts } from 'components/sanaText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetFilters } from 'behavior/products/search/searchFilters';
import { makeRichText } from 'utils/render';
import { useCallback } from 'react';

const Caption = ({ selectedManufacturer, resetFilters }) => {
  const [caption, filtesSelected, resetFiltersText] = useSanaTexts(['SearchBar_Caption', 'SearchBar_FiltersSelected', 'SearchBar_ResetFilters']).texts;
  const onClick = useCallback(() => resetFilters(), []);

  return (
    <div className={styles.searchCaptionWrapper}>
      <div className={styles.searchTitle}>{makeRichText(caption)}</div>
      {selectedManufacturer && (
        <div className={styles.captionReset}>
          <div className={styles.searchCaption}>{makeRichText(filtesSelected)}</div>
          <div className={styles.resetFiltersWrp}><button onClick={onClick} className={styles.resetFiltersLink}>{resetFiltersText}</button></div>
        </div>
      )}
    </div>
  );
};

Caption.propTypes = {
  selectedManufacturer: PropTypes.string,
  resetFilters: PropTypes.func,
};

const mapStateToProps = ({
  searchFilters,
}) => ({
  selectedManufacturer: searchFilters && searchFilters.selectedManufacturer,
});

export default connect(mapStateToProps, { resetFilters })(Caption);