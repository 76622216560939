export const PRODUCT_SEARCHFILTERS_MANUFACTURER_REQUESTED = 'PRODUCT/SEARCHFILTERS/MANUFACTURER/REQUESTED';
export const requestManufacturers = () => ({
  type: PRODUCT_SEARCHFILTERS_MANUFACTURER_REQUESTED,
});

export const PRODUCT_SEARCHFILTERS_MANUFACTURER_RECEIVED = 'PRODUCT/SEARCHFILTERS/MANUFACTURER/RECEIVED';
export const manufacturersReceived = manufacturers => ({
  type: PRODUCT_SEARCHFILTERS_MANUFACTURER_RECEIVED,
  payload: manufacturers,
});

export const PRODUCT_SEARCHFILTERS_MANUFACTURER_SELECTION = 'PRODUCT/SEARCHFILTERS/MANUFACTURER/SELECTION';
export const manufacturerSelection = code => ({
  type: PRODUCT_SEARCHFILTERS_MANUFACTURER_SELECTION,
  payload: code,
});

export const PRODUCT_SEARCHFILTERS_MANUFACTURER_SELECTED = 'PRODUCT/SEARCHFILTERS/MANUFACTURER/SELECTED';
export const manufacturerSelected = code => ({
  type: PRODUCT_SEARCHFILTERS_MANUFACTURER_SELECTED,
  payload: code,
});

export const PRODUCT_SEARCHFILTERS_MODELS_REQUESTED = 'PRODUCT/SEARCHFILTERS/MODELS/REQUESTED';
export const requestModels = (keywords, count) => ({
  type: PRODUCT_SEARCHFILTERS_MODELS_REQUESTED,
  payload: { keywords, count },
});

export const PRODUCT_SEARCHFILTERS_MODELS_RECEIVED = 'PRODUCT/SEARCHFILTERS/MODELS/RECEIVED';
export const modelsReceived = models => ({
  type: PRODUCT_SEARCHFILTERS_MODELS_RECEIVED,
  payload: models,
});

export const PRODUCT_SEARCHFILTERS_MODELS_SELECTION = 'PRODUCT/SEARCHFILTERS/MODELS/SELECTION';
export const modelSelection = model => ({
  type: PRODUCT_SEARCHFILTERS_MODELS_SELECTION,
  payload: model,
});

export const PRODUCT_SEARCHFILTERS_MODELS_SELECTED = 'PRODUCT/SEARCHFILTERS/MODELS/SELECTED';
export const modelSelected = model => ({
  type: PRODUCT_SEARCHFILTERS_MODELS_SELECTED,
  payload: model,
});

export const PRODUCT_SEARCHFILTERS_MODELS_TITLEREQUESTED = 'PRODUCT/SEARCHFILTERS/MODELS/TITLEREQUESTED';
export const requestModelTitle = () => ({
  type: PRODUCT_SEARCHFILTERS_MODELS_TITLEREQUESTED,
});

export const PRODUCT_SEARCHFILTERS_MODELS_TITLERECEIVED = 'PRODUCT/SEARCHFILTERS/MODELS/TITLERECEIVED';
export const modelTitleReceived = modelTitle => ({
  type: PRODUCT_SEARCHFILTERS_MODELS_TITLERECEIVED,
  payload: modelTitle,
});

export const PRODUCT_SEARCHFILTERS_MODELS_CLEAR = 'PRODUCT/SEARCHFILTERS/MODELS/CLEAR';
export const modelsClear = () => ({
  type: PRODUCT_SEARCHFILTERS_MODELS_CLEAR,
});

export const PRODUCT_SEARCHFILTERS_LOAD_SELECTED = 'PRODUCT/SEARCHFILTERS/LOAD/SELECTED';
export const loadSelectedItems = ({ selectedManufacturer, selectedModel }) => ({
  type: PRODUCT_SEARCHFILTERS_LOAD_SELECTED,
  payload: { selectedManufacturer, selectedModel },
});

export const PRODUCT_SEARCHFILTERS_MODEL_RESET = 'PRODUCT/SEARCHFILTERS/MODEL/RESET';
export const resetModel = () => ({
  type: PRODUCT_SEARCHFILTERS_MODEL_RESET,
});

export const PRODUCT_SEARCHFILTERS_RESET = 'PRODUCT/SEARCHFILTERS/RESET';
export const resetFilters = () => ({
  type: PRODUCT_SEARCHFILTERS_RESET,
});