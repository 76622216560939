import {
  PRODUCT_SEARCHFILTERS_MANUFACTURER_REQUESTED,
  manufacturersReceived,
  PRODUCT_SEARCHFILTERS_MODELS_REQUESTED,
  modelsReceived,
  PRODUCT_SEARCHFILTERS_MANUFACTURER_SELECTION,
  PRODUCT_SEARCHFILTERS_MODELS_SELECTION,
  modelSelected,
  manufacturerSelected,
  PRODUCT_SEARCHFILTERS_MODELS_TITLEREQUESTED,
  PRODUCT_SEARCHFILTERS_MODELS_SELECTED,
  modelTitleReceived,
  loadSelectedItems,
  PRODUCT_SEARCHFILTERS_RESET,
} from './actions';
import { switchMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { manufacturersQuery, modelsQuery, modelTitleQuery } from './queries';
import { EMPTY, merge, of } from 'rxjs';
import { searchFiltersChanged } from 'behavior/events';
import { APP_INIT } from 'behavior/app';
import { iEquals } from 'utils/helpers';

const searchFiltersEpic = (action$, state$, { api }) => {
  const onManufacturersRequested$ = action$.pipe(
    ofType(PRODUCT_SEARCHFILTERS_MANUFACTURER_REQUESTED),
    switchMap(_action => api.graphApi(manufacturersQuery).pipe(
      map(({ catalog: { manufacturers } }) => manufacturersReceived(manufacturers)),
    )),
  );

  const onManufacturerSelected$ = action$.pipe(
    ofType(PRODUCT_SEARCHFILTERS_MANUFACTURER_SELECTION),
    switchMap(action => {
      const { searchFilters: { selectedManufacturer } } = state$.value;
      if (iEquals(selectedManufacturer, action.payload))
        return EMPTY;

      api.setManufacturer(action.payload);
      api.setModel('');
      return of(searchFiltersChanged(), manufacturerSelected(action.payload));
    }),
  );

  const onModelsRequested$ = action$.pipe(
    ofType(PRODUCT_SEARCHFILTERS_MODELS_REQUESTED),
    switchMap(action => api.graphApi(modelsQuery, { options: action.payload }).pipe(
      map(({ catalog: { modelsSearch } }) => modelsReceived(modelsSearch)),
    )),
  );

  const onModelSelected$ = action$.pipe(
    ofType(PRODUCT_SEARCHFILTERS_MODELS_SELECTION),
    switchMap(action => {
      api.setModel(action.payload.number);
      api.setManufacturer(action.payload.manufacturer);
      return of(searchFiltersChanged(), modelSelected(action.payload));
    }),
  );

  const onModelTitleRequested$ = action$.pipe(
    ofType(PRODUCT_SEARCHFILTERS_MODELS_TITLEREQUESTED, PRODUCT_SEARCHFILTERS_MODELS_SELECTED),
    switchMap(_action => api.graphApi(modelTitleQuery).pipe(
      map(({ catalog: { modelTitle } }) => modelTitleReceived(modelTitle)),
    )),
  );

  const onAppInit$ = action$.pipe(
    ofType(APP_INIT),
    switchMap(_action => {
      const selectedManufacturer = api.getManufacturer();
      const selectedModel = api.getModel();
      return of(loadSelectedItems({ selectedManufacturer, selectedModel }));
    }),
  );

  const onReset$ = action$.pipe(
    ofType(PRODUCT_SEARCHFILTERS_RESET),
    switchMap(_action => {
      api.setManufacturer('');
      api.setModel('');
      return of(searchFiltersChanged());
    }),
  );

  return merge(
    onManufacturersRequested$,
    onManufacturerSelected$,
    onModelsRequested$,
    onModelSelected$,
    onModelTitleRequested$,
    onAppInit$,
    onReset$,
  );
};

export default searchFiltersEpic;

