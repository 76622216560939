import { loadMyShippingInfoPageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { initSystemPageContent } from '../system';

export default (_routeData, _state$, { api }) => {
  return api.graphApi(loadMyShippingInfoPageQuery).pipe(
    map(({ pages, viewer: { defaultAddressId, customer: { shippingAddresses } } }) => {
      const page = pages.shippingInfo;
      if (!page)
        return null;

      page.component = PageComponentNames.ShippingInfo;
      const shippingInfo = {
        shippingAddresses,
        defaultAddressId,
      };
      page.shippingInfo = shippingInfo;
      return { page };
    }),
    initSystemPageContent(),
  );
};