import {
  SHIPPING_ADDRESS_REMOVE,
  SHIPPING_ADDRESS_SUBMITTED,
  shippingAddressRemoved,
  UPDATE_DEFAULT_SHIPPING_ADDRESS_ID,
  defaultShippingAddressIdUpdated,
} from './actions';
import { switchMap, mapTo, startWith, mergeMap, takeUntil } from 'rxjs/operators';
import { saveShippingAddressQuery, removeShippingAddressQuery, updateDefaultShippingAddressIdQuery } from './queries';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { merge, of } from 'rxjs';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { LOCATION_CHANGED } from 'behavior/events';
import catchDeleteAddressError from './errorHandler';

const epic = (action$, _state$, dependencies) => {
  const { api, logger } = dependencies;
  const myShippingInfoRoute = routesBuilder.forMyShippingInfo();
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const savingShippingAddress$ = action$.pipe(
    ofType(SHIPPING_ADDRESS_SUBMITTED),
    switchMap(action => api.graphApi(saveShippingAddressQuery, { address: action.payload }, { retries: 0 }).pipe(
      mapTo(navigateTo(myShippingInfoRoute)),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormLockKeys.MyShippingInfo), unsetLoading)),
      retryWithToast(action$, logger, _ => of(unlockForm(FormLockKeys.MyShippingInfo), unsetLoading)),
      startWith(setLoading),
      takeUntil(locationChanged$),
    )),
  );

  const removingShippingAddress$ = action$.pipe(
    ofType(SHIPPING_ADDRESS_REMOVE),
    switchMap(action => api.graphApi(removeShippingAddressQuery, { id: action.payload }, { retries: 0 }).pipe(
      mergeMap(_ => [
        shippingAddressRemoved({ shippingAddressId: action.payload }),
        unsetLoading,
      ]),
      catchDeleteAddressError(action.payload, of(unsetLoading)),
      startWith(setLoading),
      takeUntil(locationChanged$),
    )),
  );

  const updateDefaultShippingAddressId$ = action$.pipe(
    ofType(UPDATE_DEFAULT_SHIPPING_ADDRESS_ID),
    switchMap(action => api.graphApi(updateDefaultShippingAddressIdQuery, { id: action.payload }, { retries: 0 }).pipe(
      mergeMap(_ => of(
        defaultShippingAddressIdUpdated(action.payload),
        unsetLoading,
      )),
      startWith(setLoading),
      takeUntil(locationChanged$),
    )),
  );

  return merge(savingShippingAddress$, removingShippingAddress$, updateDefaultShippingAddressId$);
};

export default epic;