import {
  PRODUCT_SEARCHFILTERS_MANUFACTURER_RECEIVED,
  PRODUCT_SEARCHFILTERS_MODELS_RECEIVED,
  PRODUCT_SEARCHFILTERS_MODELS_SELECTED,
  PRODUCT_SEARCHFILTERS_MANUFACTURER_SELECTED,
  PRODUCT_SEARCHFILTERS_MODELS_TITLERECEIVED,
  PRODUCT_SEARCHFILTERS_LOAD_SELECTED,
  PRODUCT_SEARCHFILTERS_MODELS_CLEAR,
  PRODUCT_SEARCHFILTERS_MODEL_RESET,
  PRODUCT_SEARCHFILTERS_RESET,
} from './actions';
import { createReducer } from 'utils/redux';
import { NAVIGATION_REQUESTED } from 'behavior/events';
import { NAVIGATED } from 'behavior/routing';

const initialState = {
  manufacturers: [],
  models: [],
  selectedModel: '',
  selectedManufacturer: '',
  modelTitle: '',
  disabled: false,
};

export default createReducer(initialState, {
  [PRODUCT_SEARCHFILTERS_MANUFACTURER_RECEIVED]: onManufacturersReceived,
  [PRODUCT_SEARCHFILTERS_MODELS_RECEIVED]: onModelsReceived,
  [PRODUCT_SEARCHFILTERS_MODELS_SELECTED]: onModelSelected,
  [PRODUCT_SEARCHFILTERS_MANUFACTURER_SELECTED]: onManufacturerSelected,
  [PRODUCT_SEARCHFILTERS_MODELS_TITLERECEIVED]: onModelTitleReceived,
  [PRODUCT_SEARCHFILTERS_MODELS_CLEAR]: onModelsClear,
  [PRODUCT_SEARCHFILTERS_LOAD_SELECTED]: onLoad,
  [PRODUCT_SEARCHFILTERS_MODEL_RESET]: onModelReset,
  [PRODUCT_SEARCHFILTERS_RESET]: onReset,
  [NAVIGATION_REQUESTED]: onSearchFiltersDisable,
  [NAVIGATED]: onSearchFiltersEnable,
});

function onLoad(state, action) {
  const { selectedModel, selectedManufacturer } = action.payload;
  return { ...state, selectedModel, selectedManufacturer };
}

function onManufacturersReceived(state, action) {
  return { ...state, manufacturers: action.payload };
}

function onModelsReceived(state, action) {
  return { ...state, models: action.payload };
}

function onModelSelected(state, action) {
  return { ...state, selectedModel: action.payload.number, selectedManufacturer: action.payload.manufacturer, models: [] };
}

function onManufacturerSelected(state, action) {
  return { ...state, selectedManufacturer: action.payload, selectedModel: '', models: [], modelTitle: '' };
}

function onModelTitleReceived(state, action) {
  return { ...state, modelTitle: action.payload };
}

function onModelsClear(state, _action) {
  return { ...state, models: [] };
}

function onSearchFiltersDisable(state, _action) {
  return { ...state, disabled: true };
}

function onSearchFiltersEnable(state, _action) {
  return { ...state, disabled: false };
}

function onModelReset(state, _action) {
  return { ...state, selectedModel: '', models: [], modelTitle: '' };
}

function onReset(state, _action) {
  return { ...state, selectedModel: '', models: [], modelTitle: '', selectedManufacturer: '' };
}