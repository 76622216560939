import { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { createBreadcrumbStructure, useTrackingOptions } from 'utils/structureData';
import renderUntilPageChanged from 'components/objects/renderUntilPageChanged';

const Schema = ({ nodes, homeLink }) => {
  if (homeLink)
    nodes = [homeLink].concat(nodes);

  const options = useTrackingOptions();

  const schema = createBreadcrumbStructure(nodes, options);
  if (!schema)
    return null;

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

Schema.propTypes = {
  nodes: PropTypes.array.isRequired,
  homeLink: PropTypes.object,
};

export default renderUntilPageChanged(memo(Schema, _ => true));
