const PREFIX = 'MY_SHIPPING_INFO';

export const SHIPPING_ADDRESS_SUBMITTED = `${PREFIX}/SHIPPING_ADDRESS_SUBMITTED`;
export const submitShippingAddress = shippingAddress => ({
  type: SHIPPING_ADDRESS_SUBMITTED,
  payload: shippingAddress,
});

export const SHIPPING_ADDRESS_REMOVE = `${PREFIX}/SHIPPING_ADDRESS_REMOVE`;
export const removeShippingAddress = id => ({
  type: SHIPPING_ADDRESS_REMOVE,
  payload: id,
});

export const SHIPPING_ADDRESS_REMOVED = `${PREFIX}/SHIPPING_ADDRESS_REMOVED`;
export const shippingAddressRemoved = result => ({
  type: SHIPPING_ADDRESS_REMOVED,
  payload: result,
});

export const UPDATE_DEFAULT_SHIPPING_ADDRESS_ID = `${PREFIX}/UPDATE_DEFAULT_SHIPPING_ADDRESS_ID`;
export const updateDefaultShippingAddressId = id => ({
  type: UPDATE_DEFAULT_SHIPPING_ADDRESS_ID,
  payload: id,
});

export const DEFAULT_SHIPPING_ADDRESS_ID_UPDATED = `${PREFIX}/DEFAULT_SHIPPING_ADDRESS_ID_UPDATED`;
export const defaultShippingAddressIdUpdated = id => ({
  type: DEFAULT_SHIPPING_ADDRESS_ID_UPDATED,
  payload: id,
});