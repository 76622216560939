import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { toasts } from 'behavior/toasts';
import { SimpleText } from 'components/sanaText';

const catchDeleteAddressError = (addressId, observable) =>
  catchError(e => {
    if (e.response && e.response.errors && e.response.errors.length) {
        toasts.error(<SimpleText textKey="MyShippingInfo_OnDeleteErrorMessage" formatWith={[addressId]} />);

        if (observable)
            return observable;

        return EMPTY;
    }

    throw e;
  });

export default catchDeleteAddressError;
