import { createValueStorage, ValueStorageOptions } from './cookieValueStorage';

const MANUFACTURER_COOKIE = 'Manufacturer';
const MODEL_COOKIE = 'Model';

export function createManufacturerStorage(options: Omit<ValueStorageOptions, 'cookieName'>) {
    return createValueStorage({ ...options, cookieName: MANUFACTURER_COOKIE });
}

export function createModelStorage(options: Omit<ValueStorageOptions, 'cookieName'>) {
    return createValueStorage({ ...options, cookieName: MODEL_COOKIE });
}