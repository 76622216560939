import styles from './Summary.module.scss';
import { memo } from 'react';
import Spinner from 'components/primitives/spinner/Spinner';
import { SimpleText } from 'components/sanaText';
import { joinClasses } from 'utils/helpers';
import PropTypes from 'prop-types';

const LoadingIndicator = ({ className = '' }) => (
  <div className={joinClasses(styles.loader, className)}>
    <Spinner className={styles.indicator} />
    <div className={styles.loaderTitle}><SimpleText textKey="BasketSummary_AddingProduct" /></div>
  </div>
);

export default memo(LoadingIndicator);

LoadingIndicator.propTypes = {
  className: PropTypes.string,
};